import styled from 'styled-components';
import { baseTheme } from '../../../../../../global/styles/theme';

export const ProductsTableRow = styled('tr')`
  width: 100%;
  .flex-container {
    display: flex;
    justify-content: space-between;
  }

  .flex-container-column {
    display: flex;
    flex-direction: column;
  }
  .input-accent {
    width: 300px;
  }
  .container-table {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .input {
    width: 70px;
    margin-left: 10px;
  }
  .input-data {
    width: 100px;
    margin-left: 10px;
  }
  .checkbox-container {
    width: 100%;
    border-top: 1px solid ${baseTheme.colors.stroking};
  }
  .table-cell {
    font-size: 16px;
    padding: 16px 0;
    font-weight: 400;
    color: ${baseTheme.colors.primary};
    vertical-align: top;
    width: 10%;

    .grid-block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;

      div {
        display: flex;
        flex-direction: column;
        word-break: break-word;
      }

      .name {
        .name-container {
          display: inline;
          white-space: normal;

          .single-word {
            margin-right: 5px;
            white-space: nowrap;
          }

          .same-word {
            /*    background-color: ${baseTheme.colors.focus};
            color: ${baseTheme.colors.white}; */
            height: 20px;
            border-radius: 5px;
          }
          .synonym-word {
            background-color: #ff6600;
            color: ${baseTheme.colors.white};
            border-radius: 5px;
            height: 20px;
            padding: 0px 10px;
          }
          .not-same-word {
            background-color: ${baseTheme.colors.error};
            color: ${baseTheme.colors.white};
            border-radius: 5px;
            height: 20px;
            padding: 0px 10px;
          }
        }

        @media only screen and (max-width: 550px) {
          max-width: 150px;
        }
      }

      .flex-block {
        display: flex;
        justify-content: flex-start !important;
        min-width: 140px;

        .name {
          .name-container {
            display: inline;
            white-space: normal;
            .same-word {
              /*    background-color: ${baseTheme.colors.focus};
              color: ${baseTheme.colors.white}; */
              height: 20px;
              border-radius: 5px;
            }
            .synonym-word {
              background-color: #ff6600;
              color: ${baseTheme.colors.white};
              border-radius: 5px;
              height: 20px;
              padding: 0px 10px;
            }
            .not-same-word {
              background-color: ${baseTheme.colors.error};
              color: ${baseTheme.colors.white};
              border-radius: 5px;
              height: 20px;
              padding: 0px 10px;
            }
          }

          @media only screen and (max-width: 550px) {
            max-width: 150px;
          }
        }
      }
    }

    &.product-info {
      width: 15%;
    }

    &.item-number {
      width: 1.6%;
    }

    &.item-number-operator,
    &.item-number-operator-edit,
    &.item-number-pharmacy,
    &.item-number-pharmacy-edit {
      width: 5%;
    }

    &.quantity {
      width: 10%;
      span {
        margin-top: 4px;
      }
    }

    &.amount-pharmacy-20,
    &.counter-pharmacy-20 {
      width: 20% !important;
    }

    &.quantity-edit {
      width: 11%;
    }

    &.quantity-operator {
      width: 13.75%;
    }

    &.amount {
      width: 35%;
      font-weight: 700;

      .current-price-block {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-right: 45px;

        .current-price-checkbox {
          margin: -15px 0 0 13px;
        }
      }
    }

    &.amount-edit {
      width: 12%;
      padding-left: 10px;
    }

    &.amount-operator {
      width: 13.75%;
      padding: 16px 0;
    }

    &.amount-operator-new,
    &.amount-operator-new-edit {
      width: 20%;
      padding: 16px 0;
      color: ${baseTheme.colors.warning};
    }

    &.amount-pharmacy-new-edit {
      width: 12%;
      padding-left: 10px;
      color: ${baseTheme.colors.warning};
    }

    &.counter {
      width: 20%;
      .pharmacy-count,
      span {
        margin-top: 3px;
      }

      span {
        margin-right: 10px;
      }

      .not-enough {
        color: ${baseTheme.colors.warning};
        span {
          color: ${baseTheme.colors.warning};
        }
      }

      .zero {
        color: ${baseTheme.colors.error};
        span {
          color: ${baseTheme.colors.error};
        }
      }

      .green {
        color: ${baseTheme.colors.focus};
        padding-top: 10px;
      }
    }

    &.counter-operator,
    &.quantity-pharmacy {
      width: 20%;
    }

    &.table-cell__info {
      width: 15%;

      .name {
        font-weight: 650;
        margin-bottom: 10px;

        @media only screen and (max-width: 550px) {
          max-width: 150px;
        }
      }

      .packing {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 4px;
        padding-right: 25px;
      }

      .manufacturer {
        color: ${baseTheme.colors.secondary};
      }

      .needs-recipe {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 500;
        color: ${baseTheme.colors.warning};
      }
    }

    &.table-cell__info-edit {
      width: 16%;
    }

    &.table-cell__info-pharmacy {
      width: 45%;
    }

    &.table-cell__info-operator {
      min-width: 253px;
      width: 40%;
    }
    &.table-cell__info-operator-accent {
      min-width: 253px;
      width: 40%;
      background-color: pink;
    }

    &.table-cell__info-pharmacy {
      min-width: 320px;

      @media only screen and (max-width: 550px) {
        min-width: 120px;
      }
    }

    &.table-cell__info-operator-edit {
      width: 27%;
      min-width: 243px;
    }

    &.table-cell__info-pharmacy-edit {
      width: 25%;
    }

    &.quantity-operator-edit,
    &.amount-operator-edit,
    &.counter-operator-edit,
    &.counter-operator-edit {
      width: 13.6%;
      padding: 16px 0;
      word-break: break-word;
    }

    .table-cell__amount-discount {
      text-decoration: line-through;
    }
    .table-cell__amount-accent {
      color: rgb(31, 160, 105);
    }
    .promo-code {
      margin-top: 5px;
      margin-bottom: 5px;
      font-weight: 400;
    }
    .table-cell__amount-accent {
      color: rgb(31, 160, 105);
    }

    &.quantity-pharmacy-edit,
    &.amount-pharmacy-edit,
    &.counter-pharmacy-edit {
      width: 14.5%;
    }

    &.amount-pharmacy-edit {
      padding-left: 10px;
    }

    &.analog-btn {
      width: 10%;
    }
  }
`;

export const AnalogRow = styled('tr')`
  width: 100%;

  th,
  td {
    background-color: ${baseTheme.colors.background};
  }

  .table-cell {
    font-size: 16px;
    padding: 16px 0;
    font-weight: 400;
    color: ${baseTheme.colors.primary};
    vertical-align: top;
    width: 10%;
    border-bottom: 1px solid ${baseTheme.colors.stroking};

    .grid-block {
      display: grid;
      grid-template-columns: 1fr 1fr;

      div {
        display: flex;
        flex-direction: column;
        word-break: break-word;
      }

      .flex-block {
        display: flex;
        justify-content: flex-start !important;
      }
    }

    &.product-info {
      width: 15%;
    }

    &.item-number {
      width: 1.6%;
    }

    &.item-number-operator,
    &.item-number-operator-edit,
    &.item-number-pharmacy,
    &.item-number-pharmacy-edit {
      width: 5%;
    }

    &.quantity {
      width: 10%;
      span {
        margin-top: 4px;
      }
    }

    &.quantity-edit {
      width: 11%;
    }

    &.quantity-operator {
      width: 20%;
    }

    &.amount {
      width: 35%;
      font-weight: 700;

      .current-price-block {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-right: 45px;

        .current-price-checkbox {
          margin: -15px 0 0 13px;
        }
      }
    }

    &.amount-edit {
      width: 12%;
      padding-right: 100px;
    }

    &.amount-operator {
      width: 20%;
      padding: 16px 0;
    }

    &.amount-operator-new,
    &.amount-operator-new-edit {
      width: 20%;
      padding: 16px 0;
      color: ${baseTheme.colors.warning};
    }

    &.analog-btn {
      width: 2%;
      .btn {
        max-width: 122px;
        width: 100%;
      }
    }

    &.counter {
      width: 9%;
      .pharmacy-count,
      span {
        margin-top: 3px;
      }

      span {
        margin-right: 10px;
      }

      .not-enough {
        color: ${baseTheme.colors.warning};
        span {
          color: ${baseTheme.colors.warning};
        }
      }

      .zero {
        color: ${baseTheme.colors.error};
        span {
          color: ${baseTheme.colors.error};
        }
      }

      .green {
        color: ${baseTheme.colors.focus};
        padding-top: 10px;
      }
    }

    &.counter-operator,
    &.quantity-pharmacy {
      width: 20%;
    }

    &.amount-pharmacy-20,
    &.counter-pharmacy-20 {
      width: 20% !important;
    }

    &.table-cell__info {
      width: 15%;

      .name {
        font-weight: 650;
        margin-bottom: 10px;
      }

      .packing {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 4px;
        padding-right: 25px;
      }

      .manufacturer {
        color: ${baseTheme.colors.secondary};
      }

      .needs-recipe {
        margin-top: 16px;
        font-size: 14px;
        font-weight: 500;
        color: ${baseTheme.colors.warning};
      }
    }

    &.table-cell__info-edit {
      width: 16%;
    }

    &.table-cell__info-operator {
      width: 40%;
    }

    &.table-cell__info-pharmacy {
      width: 45%;
    }

    &.table-cell__info-operator {
      min-width: 253px;
    }

    &.table-cell__info-pharmacy {
      min-width: 320px;
    }

    &.table-cell__info-operator-edit {
      width: 27%;
      min-width: 243px;
    }

    &.table-cell__info-pharmacy-edit {
      width: 25%;
    }

    &.quantity-operator-edit,
    &.amount-operator-edit,
    &.counter-operator-edit,
    &.amount-pharmacy-edit,
    &.counter-operator-edit,
    &.analog-btn,
    &.counter-pharmacy-edit {
      width: 17%;
      padding: 16px 0;
    }

    &.quantity-pharmacy-edit,
    &.amount-pharmacy-edit,
    &.counter-pharmacy-edit {
      width: 14.5%;
    }

    &.amount-pharmacy-edit {
      padding-left: 10px;
    }

    &.analog-btn {
      width: 10%;
    }

    &.amount-pharmacy-new-edit {
      width: 12%;
      padding-left: 10px;
      color: ${baseTheme.colors.warning};
    }
  }
`;

export const Analog = styled('div')`
  background-color: ${baseTheme.colors.background};
  padding: 16px 0 0 2%;
  font-weight: 500;
  color: ${baseTheme.colors.mainLightBlue};
`;

export const AnalogDelete = styled('div')`
  cursor: pointer;
  margin-top: -22px;
  padding-right: 10px;
  width: 100%;
  text-align: end;
`;

export const ProductDelete = styled('div')`
  cursor: pointer;
  margin-top: -22px;
  padding-right: 10px;
  width: 100%;
  text-align: end;
`;
