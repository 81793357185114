import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const SalesPageContainer = styled('div')`
  max-width: 1216px;
  margin: auto;
  padding: 34px 0;

  .products-title {
    font-weight: 700;
    font-size: 32px;
    padding-top: 16px;
    color: ${baseTheme.colors.primary};
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    color: ${baseTheme.colors.secondary};
    margin: 0px;
  }

  .search-container {
    display: flex;
    justify-content: flex-start;
    padding: 24px 20px;

    .save-edit-btn {
    margin-left: 10px;
    font-weight: 500;
    font-size: 18px;
    color: ${baseTheme.colors.white};
    padding: 13px 18px;
    text-align: center;
    background-color: ${baseTheme.colors.mainLightBlue};
    border-radius: 8px;
    width: 150px !important;
    cursor: pointer;
  }


    .search-captcha-container {
      color: #7f8895;
      font-size: 16px;
      font-weight: 500;
      max-width: 750px;

      .actions-title {
        font-size: 18px;
        color: ${baseTheme.colors.secondary};
        font-size: 16px;
        margin: 5px 0px;
        color: #535d6a;
      }

        span {
          color: ${baseTheme.colors.secondary};
          font-weight: 600;
        }
      }
    }

    .serch-field-container {
      max-width: 370px;
      width: 100%;
    }

    .example-text {
      font-weight: 500;
      font-size: 16px;
      color: ${baseTheme.colors.tertiary};
      margin: 10px 0px;
    }
  }

  .table-title {
    margin: 0px;
    color: ${baseTheme.colors.tertiary};
    padding: 20px 20px;
    border-right: 1px solid #e0e5eb;
    border-left: 1px solid #e0e5eb;
  }

  .button-row {
      margin-top: 60px;
      display: flex;
      justify-content: space-around;
    }

  .empty-search-result-container {
    border: 1px solid #e0e5eb;
    border-top: none;
    height: 100%;

    .empty-search-result-text {
      margin: 0 auto;
      width: fit-content;
      font-size: 20px;
      font-weight: 500;
      color: #535d6a;
      padding: 50px 0px;
    }
  }


`;

export const Collapse = styled('div')`
  display: flex;
  color: ${baseTheme.colors.mainLightBlue};
  cursor: pointer;
  width: fit-content;
  margin: 0;
  & img {
    margin-right: 10px;
  }
`;

export const InstructionsText = styled('div')`
  display: flex;
  flex-direction: column;
  & p {
    margin: 0 0 5px 20px;
  }
`;

export const SalesPageTabsContainer = styled('div')`
  padding-top: 56px;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #e0e5eb;

  .tab-item {
    padding: 12px;
    border-radius: 4px 4px 0 0;
    border: 1px solid #e0e5eb;
    color: #535d6a;
    border-bottom: 0;
    margin-right: 4px;
    width: 33%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .type-active {
    background-color: ${baseTheme.colors.mainLightBlue};
    color: ${baseTheme.colors.white};
  }
`;

export const WebStockPageRulesTextContainer = styled('div')`
  width: 100%;
  background-color: ${baseTheme.colors.grey};
  border-radius: 10px;
  padding: 10px;
  margin-top: 50px;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  color: ${baseTheme.colors.secondary};

  .title {
    font-size: 16px;
  }

  .rules-container {
    .rule-item {
      margin: 5px 0px 5px 0px;

      span {
        font-style: italic;
        font-size: 16px;
        color: ${baseTheme.colors.error};
      }
    }
  }
`;

export const SalesPageTableContainer = styled('div')`
  border: 1px solid ${baseTheme.colors.stroking};
  border-radius: 6px;
  padding: 31px 24px;
  width: 100%;
  border-collapse: separate;
  margin-bottom: 20px;
`;

export const SalesTableContainer = styled('table')`
  width: 100%;
  border-collapse: separate;

  .search-no-results {
    td {
      padding-top: 16px;
    }
  }
`;

export const StyledFilterTitle = styled('div')`
  display: flex;
  position: relative;

  span {
    margin: 0px 10px;
  }

  div {
    img {
      transform: rotate(90deg);
    }
  }
`;

export const SalesTableHeaderRow = styled('tr')`
  .header-row {
    font-size: 16px;
    color: ${baseTheme.colors.secondary};
    font-weight: 400;
    text-align: start;
    padding: 16px;
    max-width: 180px !important;
    border-bottom: 1px solid ${baseTheme.colors.stroking};
  }

  .header-row:first-child {
    padding-left: 0;
  }

  .header-row-lg {
    width: 25%;
  }

  .header-row-md {
    width: 20%;
  }

  .header-row-sm {
    width: 10%;
  }
`;

export const SalesTable = styled('tbody')`
  .table-row {
    display: flex;
  }
  .vertical-align-top {
    vertical-align: top;
  }
  .product-item {
    padding: 24px 10px 16px 16px;
    font-size: 16px;
    font-weight: 400;
    color: ${baseTheme.colors.primary};

    div {
      width: 80%;
    }

    .textarea {
      min-height: 40px !important;
      height: 40px;
    }

    .pencil-icon-container {
      width: 50px;
    }
  }

  .product-item:first-child {
    padding: 24px 10px 16px 0px;
  }

  .item-edit {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .save-edit-btn {
    margin-top: 16px;
    font-weight: 500;
    font-size: 18px;
    color: ${baseTheme.colors.white};
    padding: 7px 7px;
    text-align: center;
    background-color: ${baseTheme.colors.mainLightBlue};
    border-radius: 8px;
    cursor: pointer;
  }
`;

export const TableBodyContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .table-cell-lg {
    width: 40% !important;
  }

  .table-cell-md {
    width: 30% !important;
  }

  .table-cell-sm {
    width: 20% !important;
  }

  .table-cell {
    padding: 12px;
    border: 1px solid ${baseTheme.colors.stroking};
    color: ${baseTheme.colors.secondary};
    width: 25%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .price-input {
      width: 100%;
      color: #535d6a;
      font-size: 16px;
      -webkit-appearance: none;
      border: 2px solid ${baseTheme.colors.stroking};
      border-radius: 10px;
      padding: 5px 10px;
    }

    .error-input {
      border: 2px solid ${baseTheme.colors.error};
    }

    .error-msg {
      position: absolute;
      bottom: 20px;
      color: ${baseTheme.colors.error};
      width: max-content;
      height: 20px;
    }

    .disabled {
      pointer-events: none;
      background: ${baseTheme.colors.disabled} !important;
      border: 2px solid ${baseTheme.colors.disabled};
    }

    .assembly-btn {
      padding: 12px 24px;
      border-radius: 8px;
      background-color: ${baseTheme.colors.focus};
      cursor: pointer;
      margin-right: 24px;
      color: ${baseTheme.colors.white};
    }

    .save-button {
      background-color: #0a77c7;
      border: transparent 1px solid;
    }

    .cancel-button {
      background-color: #ffff;
      color: #535d6a;
    }

    input {
      width: 100%;
      color: ${baseTheme.colors.secondary};
      font-size: 16px;
      -webkit-appearance: none;
      border: none;
      padding: 0;
      background-color: inherit;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: ${baseTheme.colors.tertiary};
      }
    }
  }

  .name-container {
    display: flex;
    flex-direction: column;

    img {
      cursor: pointer;
      margin-right: auto;

      &:active {
        scale: 1.3;
      }
    }
  }

  .manufacturer {
    color: #535d6a;
    font-size: 16px;
    margin-right: auto;
    text-align: start;

    &-name {
      font-weight: 650;
    }
  }

  .product-link {
    margin-right: auto;

    &:hover {
      text-decoration: underline;
      color: ${baseTheme.colors.focus};
    }
  }
`;

export const ButtonChangePharmacy = styled('div')`
  padding: 12px 24px;
  border-radius: 8px;
  width: 180px;
  margin-top: 20px;
  background-color: ${baseTheme.colors.focus};
  cursor: pointer;
  color: ${baseTheme.colors.white};
`;

export const ContainerInput = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  height: 50px;
  border-radius: 20px;
  background: #f7f7f7;
  padding-left: 15px;
  padding-right: 15px;

  .search-input {
    background: transparent;
    outline: none;
    border: none;
    width: 280px;
  }
  .clear-input {
    cursor: pointer;
  }
`;

export const ButtonSearch = styled('div')`
  cursor: pointer;
  background: #0a77c7;
  border-radius: 20px;
  padding: 10px 24px;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    transition: 200ms;
    background-color: #003e6e;
  }
`;

export const SearchContainer = styled('div')`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
`;

export const CreateOrderFormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  .field-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .label {
      margin: 10px 0px;
    }

    input {
      width: 300px;
      color: ${baseTheme.colors.secondary};
      font-size: 16px;
      -webkit-appearance: none;
      border: 1px solid black;
      border-radius: 5px;
      padding: 6px;
      background-color: inherit;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: ${baseTheme.colors.tertiary};
      }
    }
  }

  .submit-btn {
    width: 200px;
    margin: 20px;
    font-weight: 400;
    font-size: 16px;
    padding: 8px 20px;
    border-radius: 8px;
    background-color: ${baseTheme.colors.focus};
    cursor: pointer;
    color: ${baseTheme.colors.white};
    border: none;
  }

  .error-field {
    position: absolute;
    top: 75px;
    color: ${baseTheme.colors.error};
    width: max-content;
    height: 20px;
  }

  .disabled {
    pointer-events: none;
    background: ${baseTheme.colors.disabled} !important;
    border: 2px solid ${baseTheme.colors.disabled};
  }
`;
