import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import Hamburger from 'hamburger-react';
import * as Styled from './Header.styles';
import * as actions from '../../containers/LoginPage/actions';
import { selectors } from '../../containers/LoginPage/reducer';
import { selectors as selectorsProduct } from '../../containers/ProductsPage/reducer';
import logo from '../../global/media/header-logo.svg';
import { setToLocalStorage, getFromLocalStorage } from '../../global/helpers';
import * as actionService from '../../containers/EditPage/actions';
import * as actionOrder from '../../containers/OrdersPage/actions';
import * as actionProducts from '../../containers/ProductsPage/actions';
import * as actionPharmacist from '../ActivePharmacistBlock/actions';
import * as constants from '../../containers/ProductsPage/constants';
import { UserRole } from '../../containers/OrdersPage/types';
import QuestionsModal from '../QuestionsModal';
import ModalWindow from '../ModalWindow';

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const networkForContent = useSelector(selectorsProduct.network);

  const accessToken = useSelector(selectors.accessToken);
  const [isDesktopMode, setIsDesktopMode] = useState(window.innerWidth >= 1200);

  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [isQuestionsOpen, setIsQuestionsOpen] = useState<boolean>(false);
  const [network, setNetwork] = useState<string>('Выберите сеть');
  const storageNetworkForContent = getFromLocalStorage('networkData');

  const dataUser = useSelector(selectors.userData);
  const role = getFromLocalStorage('role');
  const [education, setEducation] = useState(
    history.location.pathname.includes('/test-orders') || false
  );

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const truRole =
      role === UserRole.MANAGER ||
      role === UserRole.PHARMACIST ||
      role === UserRole.PHARMACY_MANAGER ||
      role === UserRole.OPERATOR;
    if (isAuthorized && history.location.pathname === '/login' && truRole) {
      window.location.href = '/orders';
    }
  }, [isAuthorized, history.location.pathname]);

  useEffect(() => {
    if (
      history.location.pathname === '/orders' &&
      isAuthorized &&
      role === UserRole.CUSTOMER
    ) {
      window.location.href = '/';
    }
  }, [isAuthorized]);

  useEffect(() => {}, [
    history.location.pathname.includes('/test-orders') &&
      history.location.pathname.includes('/test-orders'),
  ]);

  useEffect(() => {
    if (role !== UserRole.PHARMACIST) {
      if (accessToken) {
        dispatch(actions.getUserInfo.request({ token: accessToken }));
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    }
  }, [accessToken, dispatch]);

  useEffect(() => {
    if (role === UserRole.PHARMACIST) {
      if (accessToken) {
        role !== UserRole.CONTENT &&
          dispatch(actions.getUserInfo.request({ token: accessToken }));
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    }
  }, [accessToken, dispatch, history.location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktopMode(window.innerWidth >= 1200);
    };

    const handleViewportChange = () => {
      const viewport = document.querySelector(
        'meta[name="viewport"]'
      ) as HTMLMetaElement | null;
      if (viewport) {
        setIsDesktopMode(viewport.content.includes('width=1200'));
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('viewportChange', handleViewportChange);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('viewportChange', handleViewportChange);
    };
  }, []);

  useEffect(() => {
    setNetwork(
      networkForContent?.name
        ? networkForContent?.name
        : storageNetworkForContent?.name
    );
  }, [
    networkForContent && networkForContent?.name,
    storageNetworkForContent && storageNetworkForContent?.name,
  ]);

  const handleLogout = () => {
    dispatch(actions.logout());
    setToLocalStorage('accessToken', null);
    setToLocalStorage('refreshToken', null);
    setToLocalStorage('selectedProduct', null);
    setToLocalStorage('role', null);
    setToLocalStorage('networkData', null);
    setToLocalStorage('education', false);
    setToLocalStorage('pagination', {
      unAttachedPage: 0,
      attachedPage: 0,
      blockedPage: 0,
      verificationPage: 0,
    });
    window.location.reload();
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (role !== UserRole.OPERATOR) {
      return;
    }
    const now = new Date();
    const logoutTimeMorning = new Date();
    logoutTimeMorning.setHours(8, 0, 0, 0);
    const logoutTimeEvening = new Date();
    logoutTimeEvening.setHours(22, 0, 0, 0);
    let nextLogoutTime;
    if (now < logoutTimeMorning) {
      nextLogoutTime = logoutTimeMorning;
    } else if (now < logoutTimeEvening) {
      nextLogoutTime = logoutTimeEvening;
    } else {
      logoutTimeMorning.setDate(logoutTimeMorning.getDate() + 1);
      nextLogoutTime = logoutTimeMorning;
    }
    const timeUntilLogout = nextLogoutTime.getTime() - now.getTime();
    const timeoutId = setTimeout(() => {
      dispatch(
        actionPharmacist.updateActiveUsers.request({
          sourceCode: dataUser.sourceCode || dataUser.collectingSourceCode,
          networkCode: dataUser.networkCode,
          phones: [],
        })
      );
    }, timeUntilLogout);
    return () => clearTimeout(timeoutId);
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (role !== UserRole.PHARMACIST) {
      return;
    }
    const now = new Date();
    const logoutTimeMorning = new Date();
    logoutTimeMorning.setHours(8, 30, 0, 0);
    const logoutTimeEndOfDay = new Date();
    logoutTimeEndOfDay.setHours(23, 59, 0, 0);
    let nextLogoutTime;
    if (now < logoutTimeMorning) {
      nextLogoutTime = logoutTimeMorning;
    } else if (now < logoutTimeEndOfDay) {
      nextLogoutTime = logoutTimeEndOfDay;
    } else {
      logoutTimeMorning.setDate(logoutTimeMorning.getDate() + 1);
      nextLogoutTime = logoutTimeMorning;
    }
    const timeUntilLogout = nextLogoutTime.getTime() - now.getTime();
    const timeoutId = setTimeout(handleLogout, timeUntilLogout);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleClick = (push: string) => {
    setOpen(false);
    setToLocalStorage('selectedProduct', null);
    setToLocalStorage(constants.ACTIVE_TAB_KEY, constants.UNATTACHED);
    dispatch(actionService.clearSelectedProduct());
    dispatch(actionOrder.clearSelectedOrder());
    push !== '/orders' && dispatch(actionOrder.clearOrders());
    if (push === '/orders') {
      !education
        ? dispatch(
            actionOrder.getOrders.request({
              status: '',
              limit:
                role === UserRole.OPERATOR ||
                role === UserRole.ADMIN ||
                role === UserRole.HEAD_OPERATOR
                  ? 200
                  : 100,
              postEffect: () => history.push(push),
            })
          )
        : history.push('/orders');
    } else history.push(push);
  };

  const handleNetworkClick = () => {
    if (
      role === UserRole.CONTENT ||
      role === UserRole.OPERATOR ||
      role === UserRole.HEAD_OPERATOR ||
      role === UserRole.ADMIN
    ) {
      dispatch(actionProducts.openNetworks());
    }
  };

  const handleSetEducation = (e: boolean) => {
    setEducation(e);
    setToLocalStorage('education', e);
    window.location.href = '/orders';
  };

  const getActivePage = (active: string) => {
    return history.location.pathname === active ||
      history.location.pathname.includes(active)
      ? 'active'
      : '';
  };

  const toggleView = () => {
    setOpen(false);
    let viewport = document.querySelector(
      'meta[name="viewport"]'
    ) as HTMLMetaElement;

    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.name = 'viewport';
      document.head.appendChild(viewport);
    }

    if (isDesktopMode) {
      viewport.content = 'width=device-width, initial-scale=1';
    } else {
      viewport.content = 'width=1200';
    }

    setIsDesktopMode(!isDesktopMode);

    window.dispatchEvent(new Event('viewportChange'));
  };

  const isDataPresent = (): boolean => {
    const saleInformed = getFromLocalStorage('looked-sale');

    if (!saleInformed) {
      return true;
    }
    return false;
  };

  const isProductsLocation = history.location.pathname === '/products';
  const isEditLocation = history.location.pathname === '/edit';

  return (
    <>
      <Styled.HeaderContainer>
        <Styled.HeaderContent>
          <Styled.HeaderLogoPharmacyNameContainer>
            <Styled.HeaderLogo
              onClick={() =>
                handleClick(role === UserRole.ADMIN ? '/sources' : '/orders')
              }
            >
              <img src={logo} alt="header logo" />
            </Styled.HeaderLogo>
            {isAuthorized && (
              <Styled.PharmacyNameContainer
                className={`${
                  role === UserRole.CONTENT ||
                  role === UserRole.OPERATOR ||
                  role === UserRole.HEAD_OPERATOR ||
                  (role === UserRole.ADMIN &&
                    (isProductsLocation || isEditLocation) &&
                    'active-network')
                }`}
                onClick={handleNetworkClick}
              >
                {role === UserRole.ADMIN &&
                  !isProductsLocation &&
                  !isEditLocation &&
                  'Админ'}
                {(role === UserRole.PHARMACIST ||
                  role === UserRole.MANAGER ||
                  role === UserRole.PHARMACY_MANAGER) &&
                  dataUser?.networkName}
                {role === UserRole.CONTENT && network}
                {(role === UserRole.OPERATOR ||
                  role === UserRole.ADMIN ||
                  role === UserRole.HEAD_OPERATOR) &&
                  (isProductsLocation || isEditLocation) &&
                  network}
              </Styled.PharmacyNameContainer>
            )}
          </Styled.HeaderLogoPharmacyNameContainer>
          {isAuthorized && (!isMobile || isDesktopMode) ? (
            <Styled.HeaderControls>
              {(role === UserRole.PHARMACIST ||
                role === UserRole.MANAGER ||
                role === UserRole.PHARMACY_MANAGER) && (
                <>
                  <Styled.HeaderButtonQuestions
                    onClick={() => setIsQuestionsOpen(!isQuestionsOpen)}
                  >
                    {t('Header.QUESTIONS')}
                  </Styled.HeaderButtonQuestions>
                  <Styled.HeaderButtonContainer
                    className={`${getActivePage('/test-orders')}`}
                    onClick={() => handleClick('/test-orders')}
                  >
                    {t('Header.STUDY_BTN')}
                  </Styled.HeaderButtonContainer>
                </>
              )}
              {(role === UserRole.ADMIN ||
                role === UserRole.PHARMACIST ||
                role === UserRole.HEAD_OPERATOR) && (
                <Styled.HeaderButtonContainer
                  className={`${getActivePage('/promo-code')}`}
                  onClick={() => handleClick('/promo-code')}
                >
                  {t('Header.PROMO_CODE_BTN_TEXT')}
                </Styled.HeaderButtonContainer>
              )}
              {(role === UserRole.ADMIN ||
                role === UserRole.PHARMACIST ||
                role === UserRole.MANAGER ||
                role === UserRole.PHARMACY_MANAGER) && (
                <Styled.HeaderButtonContainer
                  className={`${getActivePage('/sales')}`}
                  onClick={() => handleClick('/sales')}
                >
                  {t('Header.SALES')}
                  {role !== UserRole.ADMIN && (
                    <div
                      className={`star ${isDataPresent() ? 'not-visited' : ''}`}
                    >
                      НОВОЕ
                    </div>
                  )}
                </Styled.HeaderButtonContainer>
              )}
              {role === UserRole.ADMIN && (
                <Styled.HeaderButtonContainer
                  className={`${getActivePage('/products')}`}
                  onClick={() => handleClick('/products')}
                >
                  {t('Header.PRODUCTS_BTN_TEXT')}
                </Styled.HeaderButtonContainer>
              )}
              {(role === UserRole.MANAGER ||
                role === UserRole.CONTENT ||
                role === UserRole.PHARMACY_MANAGER ||
                role === UserRole.OPERATOR) && (
                <Styled.HeaderButtonContainer
                  className={`${getActivePage(
                    role === UserRole.ADMIN ? '/' : '/products'
                  )}`}
                  onClick={() =>
                    handleClick(role === UserRole.ADMIN ? '/' : '/products')
                  }
                >
                  {role === UserRole.ADMIN
                    ? t('Header.OPERATORS_BTN_TEXT')
                    : t('Header.PRODUCTS_BTN_TEXT')}
                </Styled.HeaderButtonContainer>
              )}
              {(role === UserRole.CONTENT || role === UserRole.ADMIN) && (
                <Styled.HeaderButtonContainer
                  className={`${getActivePage(
                    role === UserRole.ADMIN ? '/analogs' : '/analogs'
                  )}`}
                  onClick={() => handleClick('/analogs')}
                >
                  Аналоги
                </Styled.HeaderButtonContainer>
              )}
              {(role === UserRole.CONTENT ||
                role === UserRole.ADMIN ||
                role === UserRole.OPERATOR ||
                role === UserRole.HEAD_OPERATOR) && (
                <Styled.HeaderButtonContainer
                  className={`${getActivePage(
                    role === UserRole.ADMIN ? '/moderation' : '/moderation'
                  )}`}
                  onClick={() => handleClick('/moderation')}
                >
                  Модерация
                </Styled.HeaderButtonContainer>
              )}

              {((role === UserRole.PHARMACIST &&
                dataUser?.soft.toUpperCase() === 'WEB_STOCK') ||
                role === UserRole.MANAGER ||
                (role === UserRole.PHARMACY_MANAGER &&
                  dataUser?.pharmacyList.length > 0)) && (
                <Styled.HeaderButtonContainer
                  className={`${getActivePage(
                    role === UserRole.PHARMACIST ? '/web-stock' : '/web-stock'
                  )}`}
                  onClick={() => handleClick('/web-stock')}
                >
                  {t('Header.WEBSTOCK')}
                </Styled.HeaderButtonContainer>
              )}

              {role !== UserRole.CONTENT && (
                <Styled.HeaderButtonContainer
                  id="orders"
                  className={`${getActivePage(
                    role === UserRole.ADMIN || role === UserRole.HEAD_OPERATOR
                      ? '/sources'
                      : '/orders'
                  )}`}
                  onClick={() =>
                    handleClick(
                      role === UserRole.ADMIN || role === UserRole.HEAD_OPERATOR
                        ? '/sources'
                        : '/orders'
                    )
                  }
                >
                  {role === UserRole.ADMIN
                    ? t('Header.SOURCES_BTN_TEXT')
                    : t('Header.ORDER_SEARCH_BTN_TEXT')}
                </Styled.HeaderButtonContainer>
              )}
              {role === UserRole.ADMIN && (
                <Styled.HeaderButtonContainer
                  className={`${getActivePage(
                    role === UserRole.ADMIN ? '/operators' : '/products'
                  )}`}
                  onClick={() =>
                    handleClick(
                      role === UserRole.ADMIN ? '/operators' : '/products'
                    )
                  }
                >
                  {role === UserRole.ADMIN
                    ? t('Header.OPERATORS_BTN_TEXT')
                    : t('Header.PRODUCTS_BTN_TEXT')}
                </Styled.HeaderButtonContainer>
              )}
              {(role === UserRole.MANAGER ||
                role === UserRole.ADMIN ||
                role === UserRole.PHARMACY_MANAGER ||
                role === UserRole.OPERATOR) && (
                <Styled.HeaderButtonContainer
                  className={`${getActivePage('/report')}`}
                  onClick={() => handleClick('/report')}
                >
                  {t('Header.REPORT_BTN_TEXT')}
                </Styled.HeaderButtonContainer>
              )}
              {role === UserRole.MANAGER && (
                <Styled.HeaderButtonContainer
                  className={`${getActivePage('/pharmacist')}`}
                  onClick={() => handleClick('/pharmacist')}
                >
                  {t('Header.PHARMACIST_BTN_TEXT')}
                </Styled.HeaderButtonContainer>
              )}
              {(role === UserRole.ADMIN || role === UserRole.HEAD_OPERATOR) && (
                <Styled.HeaderButtonContainer
                  className={`${getActivePage('/orders')}`}
                  onClick={() => handleClick('/orders')}
                >
                  {t('Header.ORDER_SEARCH_BTN_TEXT')}
                </Styled.HeaderButtonContainer>
              )}

              {role === UserRole.ADMIN && (
                <Styled.HeaderButtonContainer
                  className={`${getActivePage('/settings')}`}
                  onClick={() => handleClick('/settings')}
                >
                  {t('Header.SETTINGS_BTN_TEXT')}
                </Styled.HeaderButtonContainer>
              )}
              <Styled.HeaderLogoutButtonContainer
                onClick={handleLogout}
                id="logout"
              >
                {t('Header.LOGOUT_BTN_TEXT')}
              </Styled.HeaderLogoutButtonContainer>
            </Styled.HeaderControls>
          ) : (
            <Hamburger color="#fff" toggled={isOpen} toggle={setOpen} />
          )}
        </Styled.HeaderContent>
      </Styled.HeaderContainer>
      {isQuestionsOpen && (
        <QuestionsModal onClose={() => setIsQuestionsOpen(false)} />
      )}
      {isOpen && (
        <ModalWindow noClose onClose={() => setOpen(false)} zIndex>
          <div style={{ background: '#003e6e', height: '100vh', bottom: 0 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Hamburger color="#fff" toggled={isOpen} toggle={setOpen} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                padding: '16px',
              }}
            >
              {role === UserRole.ADMIN && (
                <Styled.HeaderButtonContainer
                  id="desktop"
                  className=""
                  onClick={toggleView}
                  style={{
                    margin: 0,
                  }}
                >
                  {isDesktopMode
                    ? t('Header.TO_MOBILE')
                    : t('Header.TO_DESKTOP')}
                  <img
                    src={
                      isDesktopMode
                        ? '/images/phone.svg'
                        : '/images/desktop-blue.svg'
                    }
                    alt="icon"
                    style={{ marginLeft: 10 }}
                  />
                </Styled.HeaderButtonContainer>
              )}
              {(role === UserRole.OPERATOR || role === UserRole.ADMIN) && (
                <Styled.HeaderButtonContainer
                  id="report"
                  className={`${getActivePage('/report')}`}
                  onClick={() => handleClick('/report')}
                  style={{
                    margin: 0,
                  }}
                >
                  {t('Header.REPORT_BTN_TEXT')}
                </Styled.HeaderButtonContainer>
              )}
              <Styled.HeaderLogoutButtonContainer
                onClick={handleLogout}
                id="logout"
                style={{
                  margin: 0,
                }}
              >
                {t('Header.LOGOUT_BTN_TEXT')}
              </Styled.HeaderLogoutButtonContainer>
            </div>
          </div>
        </ModalWindow>
      )}
    </>
  );
};

export default Header;
