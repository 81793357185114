import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const AttentionTitle = styled('div')`
  margin-top: 5px;
  color: ${baseTheme.colors.error};
  font-weight: bold;
  font-size: 18px;
`;

export const CartHistoryModalContainer = styled('div')`
  max-height: 80vh;
  .CartHistoryTitle {
    text-align: center;
  }
  .cartChangeBLock {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 15px;
    & .cartChangeTop {
      display: flex;
      justify-content: space-between;
      width: 700px;
    }
    & .changeTopBlock {
      border: solid lightgrey 1px;
      width: 50%;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
    }
    .change-cell {
      display: flex;
      justify-content: space-between;
      width: 700px;
      .change-product-cell {
        display: flex;
        justify-content: space-between;
        border: solid lightgrey 1px;
        width: 50%;
        padding: 0 20px;
        & .change-product-count {
          min-width: 20px;
          text-align: center;
        }
      }
    }
  }
`;

export const OrderProductContainer = styled('div')`
  padding: 20px;
  border: 1px solid ${baseTheme.colors.stroking};
  background-color: white;
  border-radius: 8px;
  margin-top: 15px;

  .title-block-main {
    display: flex;
    justify-content: space-between;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid ${baseTheme.colors.stroking};
    padding-bottom: 14px;

    .title-block {
      display: flex;
      align-items: center;
      .cartHistory {
        margin-right: 10px;
        cursor: pointer;
      }
      .edit {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: ${baseTheme.colors.secondary};
        cursor: pointer;
      }

      .edit-active {
        cursor: pointer;
        font-weight: 500;
        color: ${baseTheme.colors.mainLightBlue};
        padding-bottom: 6px;
        border-bottom: 1px solid ${baseTheme.colors.mainLightBlue};
      }
    }
    .main-title {
      font-weight: 700;
      font-size: 20px;
      color: ${baseTheme.colors.mainBlue};
      padding-right: 30px;
    }

    .add-product {
      display: flex;
      align-items: center;
      color: #7f8895;
      cursor: pointer;
      div {
        display: flex;
        align-items: center;
        padding-right: 8px;
      }
    }
  }
`;

export const ProductsTableContainer = styled('table')`
  width: 100%;
  border-collapse: separate;

  tbody {
    display: block;
  }

  thead {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
`;

export const ProductsTableHeaderRow = styled('tr')`
  .table__header-cell {
    font-size: 16px;
    color: ${baseTheme.colors.secondary};
    font-weight: 400;
    text-align: start;
    padding: 16px 0;
    border-bottom: 1px solid ${baseTheme.colors.stroking};

    .header-name {
      display: flex;
      align-items: center;
      word-break: break-word;
      min-width: 150px;
      width: 150px;
    }

    .grid-block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      @media (max-width: 768px) {
        display: flex;
        width: 150px;
        gap: 0;
      }

      div {
        display: flex;
        align-items: center;
        word-break: break-word;
      }
    }

    &.product-info {
      width: 15%;
    }

    &.item-number-operator,
    &.item-number-operator-edit,
    &.item-number-pharmacy,
    &.item-number-pharmacy-edit {
      width: 5%;
    }

    &.amount-operator,
    &.quantity-operator,
    &.availability-operator,
    &.quantity-pharmacy {
      width: 13.75%;
    }

    &.amount-operator,
    &.amount-operator-edit {
      padding: 0 5px;
    }

    &.quantity-pharmacy {
      width: 20%;
    }

    &.quantity-operator-edit,
    &.amount-operator-edit,
    &.availability-operator-edit {
      width: 13.6%;
    }

    &.quantity-pharmacy-edit,
    &.amount-pharmacy-edit,
    &.availability-pharmacy-edit {
      width: 14.5%;
    }

    &.amount-pharmacy-edit {
      padding-left: 10px;
    }

    &.analog-pharmacy-edit {
      width: 10%;
    }

    &.product-info-pharmacy {
      width: 45%;
    }

    &.product-info-operator {
      width: 40%;
      @media (max-width: 768px) {
        width: 27%;
      }
    }

    &.product-info-operator-edit,
    &.product-info-pharmacy-edit {
      width: 27%;
    }

    &.product-info-pharmacy-edit {
      width: 25%;
    }

    @media only screen and (max-width: 550px) {
      font-size: 13px;

      &.product-info-pharmacy {
        width: 40%;
      }

      &.quantity-pharmacy {
        width: 30%;
      }
    }
  }
`;

export const SummaryContainer = styled('div')`
  .amount-promo-code {
    text-decoration: line-through;
    font-weight: 400 !important;
    margin-left: 5px;
  }

  .wrapper-promo-code {
    font-weight: 600;
  }

  .table-cell__amount-discount {
    text-decoration: line-through;
  }

  .sale-amount-info {
    background: ${baseTheme.colors.error};
    color: ${baseTheme.colors.white};
    padding: 5px;
    margin-left: 5px;
    border-radius: 5px;
  }

  .promo-code {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 400;
  }

  .table-cell__amount-accent {
    color: rgb(31, 160, 105);
  }

  .cancel_button {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    background-color: ${baseTheme.colors.mainLightBlue};
    color: ${baseTheme.colors.white};
    font-weight: 500;
    font-size: 14px;
    width: 200px;
    padding: 6px;
    border-radius: 8px;
    margin-bottom: 10px;

    &:hover {
      background-color: ${baseTheme.colors.mainBlue};
    }
  }

  .disabled {
    pointer-events: none;
    background: ${baseTheme.colors.disabled};
    border: 2px solid ${baseTheme.colors.disabled};
  }

  .summary {
    padding-top: 17px;
    display: flex;

    div {
      color: ${baseTheme.colors.secondary};
      margin-top: 3px;
    }

    span {
      font-weight: 700;
      font-size: 20px;
      padding-left: 8px;
    }
  }

  .summary-edit-operator-pad {
    padding-left: 0 !important;
  }

  .summary-edit-operator {
    padding-top: 17px;
    padding-left: 333px;
    padding-right: 100px;
    display: flex;
    justify-content: space-between;

    .old-price {
      display: flex;
      div {
        color: ${baseTheme.colors.secondary};
        margin-top: 3px;
      }

      span {
        font-weight: 700;
        font-size: 18px;
        padding-left: 8px;
      }
    }

    .new-price {
      display: flex;
      div {
        color: ${baseTheme.colors.secondary};
        margin-top: 3px;
      }

      span {
        color: ${baseTheme.colors.warning};
        font-weight: 700;
        font-size: 18px;
        padding-left: 8px;
      }
    }
  }

  .summary-edit-pharmacy {
    padding-top: 17px;
    display: flex;
    justify-content: space-between;

    .old-price {
      display: flex;
      div {
        color: ${baseTheme.colors.secondary};
        margin-top: 3px;
      }

      span {
        font-weight: 700;
        font-size: 18px;
        padding-left: 8px;
      }
    }

    .new-price {
      display: flex;
      padding-right: 50px;
      div {
        color: ${baseTheme.colors.secondary};
        margin-top: 3px;
      }

      span {
        color: ${baseTheme.colors.warning};
        font-weight: 700;
        font-size: 18px;
        padding-left: 8px;
      }
    }
  }

  .btn {
    padding-top: 17px;
    max-width: 372px;
    position: relative;

    .hint-block {
      width: 400px;
      .first-hint {
        margin: 10px 0 0;
        font-weight: 500;
        font-size: 14px;
      }

      .second-hint {
        margin-top: -15px;
        font-weight: 500;
        font-size: 14px;
        color: ${baseTheme.colors.secondary};
      }
    }
  }

  .btn-operator {
    max-width: 170px;
  }

  .disabled {
    pointer-events: none;
  }
`;

export const disabled = styled('div')`
  pointer-events: none;
`;
